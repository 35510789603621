import { CollapseProps } from "antd";
import clsx from "clsx";
import i18next from "i18next";
import React, { useState } from "react";
// @ts-ignore
import {
  DraggableProvided,
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import TableHeader from "../../../../../../../../Admin/components/TableHeader";
import { DragIcon } from "../../../../../../../../assets/icons";
import StyledCollapse from "../../StyledCollapse";
import RiskItem from "../RiskItem";
import styles from "./RisksTable.module.scss";

export const SORT_TITLES = [
  { title: i18next.t("dashboard:status:risk_table_header_level"), key: "level" },
  { title: i18next.t("dashboard:status:risk_table_header_description"), key: "description" },
  { title: i18next.t("dashboard:status:risk_table_header_type"), key: "type" },
];

type RiskChild = {
  key: string;
  type: string;
  title: string;
  status: string;
};

type RiskItemType = {
  key: string;
  label: string;
  children: RiskChild[];
};

type Props = {
  editMode: boolean;
  onClickItem?: (item: RiskItemType) => void;
};

export default function RisksTable({ editMode, onClickItem }: Props) {
  const [filters, setFilters] = useState({});
  const [items, setItems] = useState<RiskItemType[]>([
    {
      key: "1",
      label: "Valuation and existence of related party transactions...",
      children: [
        { key: "1-1", type: "Fraud", title: "Child 1", status: "inquiry" },
        { key: "1-2", type: "Fraud", title: "Child 2", status: "general" },
      ],
    },
    {
      key: "2",
      label: "None and no indication for the necessity to build some.",
      children: [
        { key: "2-1", type: "Fraud", title: "Child 1", status: "inquiry" },
        { key: "2-2", type: "Fraud", title: "Child 2", status: "general" },
      ],
    },
  ]);

  const onDragEnd = (result: any) => {
    const { source, destination, type } = result;

    console.log("Drag result:", result);

    // If there's no destination, exit early
    if (!destination) return;

    // If dragging top-level (parent) items
    if (type === "parent") {
      const updatedItems = [...items];
      const [movedItem] = updatedItems.splice(source.index, 1);
      updatedItems.splice(destination.index, 0, movedItem);

      setItems(updatedItems);
      return;
    }

    // Handle child-level dragging
    const sourceParentKey = source.droppableId;
    const destinationParentKey = destination.droppableId;

    const sourceParent = items.find((item) => item.key === sourceParentKey);
    const destinationParent = items.find((item) => item.key === destinationParentKey);

    if (!sourceParent || !destinationParent) return;

    if (sourceParentKey === destinationParentKey) {
      // Reorder within the same parent
      const updatedChildren = [...sourceParent.children];
      const [movedChild] = updatedChildren.splice(source.index, 1);
      updatedChildren.splice(destination.index, 0, movedChild);

      const updatedItems = items.map((item) =>
        item.key === sourceParentKey ? { ...item, children: updatedChildren } : item
      );

      setItems(updatedItems);
    } else {
      // Move between different parents
      const sourceChildren = [...sourceParent.children];
      const [movedChild] = sourceChildren.splice(source.index, 1);

      const destinationChildren = [...destinationParent.children];
      destinationChildren.splice(destination.index, 0, movedChild);

      const updatedItems = items.map((item) => {
        if (item.key === sourceParentKey) {
          return { ...item, children: sourceChildren };
        }
        if (item.key === destinationParentKey) {
          return { ...item, children: destinationChildren };
        }
        return item;
      });

      setItems(updatedItems);
    }
  };

  const collapseItems: CollapseProps["items"] = items.map((parent) => ({
    key: parent.key,
    label: <RiskItem title={parent.label} />,
    children: (
      <Droppable droppableId={parent.key} type='child'>
        {(provided: DroppableProvided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {parent.children.map((child, index) => (
              <Draggable key={child.key} draggableId={child.key} index={index}>
                {(provided: DraggableProvided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={clsx(styles.dragItemWrapper, styles.dragItemChild)}
                  >
                    {editMode && (
                      <div {...provided.dragHandleProps} className={styles.dragHandle}>
                        <DragIcon />
                      </div>
                    )}
                    <RiskItem type={child.type} title={child.title} status={child.status} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    ),
    onClick: editMode ? undefined : () => onClickItem?.(parent),
  }));

  return (
    <>
      {!editMode && (
        <TableHeader
          titles={SORT_TITLES}
          filters={filters}
          wrapperStyles={{ gridTemplateColumns: "80px 1fr 100px 30px" }}
          onChange={setFilters}
        />
      )}
      <div className={styles.list}>
        <DragDropContext onDragEnd={onDragEnd}>
          {editMode && (
            <Droppable droppableId='parent' type='parent'>
              {(provided: DroppableProvided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {items.map((parent, index) => (
                    <Draggable key={parent.key} draggableId={parent.key} index={index}>
                      {(provided: DraggableProvided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={styles.dragItemWrapper}
                        >
                          {editMode && (
                            <div className={styles.dragHandle} {...provided.dragHandleProps}>
                              <DragIcon />
                            </div>
                          )}
                          <StyledCollapse editMode={editMode} items={[collapseItems[index]]} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
          {!editMode && <StyledCollapse editMode={editMode} items={collapseItems} />}
        </DragDropContext>
      </div>
    </>
  );
}
