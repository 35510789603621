import { Input } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { addDocumentComment } from "../../../../../api/documentApi";
import toFullName from "../../../../../consts/toFullName";
import { RootState } from "../../../../../store";
import { DocumentComment } from "../../../../../types/document";

import CustomAvatar from "../../../../../components/CustomAvatar/CustomAvatar";

import { ReactComponent as EmptyStateImage } from "../../../../../assets/images/comments-empty-state.svg";

import styles from "./DocumentViewComments.module.scss";

type DocumentViewCommentsProps = {
  data: DocumentComment[];
  className?: string;
  listClassName?: string;
  onAfterSubmit?: () => void;
  customSubmit?: (text: string) => void;
};

type ParamsProps = {
  id: string;
  documentId: string;
};

export default function DocumentViewComments({
  data,
  className,
  listClassName,
  onAfterSubmit,
  customSubmit,
}: DocumentViewCommentsProps) {
  const { id: idAudit, documentId: idDocument } = useParams<ParamsProps>();
  const { user_data } = useSelector((state: RootState) => state.user);
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });
  const listRef = useRef<HTMLDivElement>(null);

  const [commentInput, setCommentInput] = useState("");

  const handleAddComment = (e: KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (commentInput) {
        setCommentInput("");
        if (customSubmit) {
          customSubmit(commentInput);
          return;
        }
        addDocumentComment({ text: commentInput }, idAudit, idDocument)
          .then(() => onAfterSubmit?.())
          .catch((error) => {
            console.log("error", error);
          });
      }
    }
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [data]);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <h3 className={styles.title}>Comments</h3>
      <div ref={listRef} className={classNames(styles.list, listClassName)}>
        {data.length > 0 ? (
          data.map((item) => (
            <div className={styles.comment} key={item.id}>
              <CustomAvatar user={item.user} />
              <div className={styles.commentContent}>
                <div className={styles.commentUser}>
                  <span className={styles.commentName}>{toFullName(item.user)}</span>
                  <span className={styles.commentDate}>
                    {dayjs(item.createdAt).format("DD MMM YYYY HH:mm")}
                  </span>
                </div>
                <span className={styles.commentText}>{item.text}</span>
              </div>
            </div>
          ))
        ) : (
          <div className={styles.emptyState}>
            <EmptyStateImage />
            <h4 className={styles.emptyTitle}>No comments</h4>
          </div>
        )}
      </div>
      <div className={styles.commentInputWrapper}>
        {user_data && (
          <div className={styles.avatarWrapper}>
            <CustomAvatar user={user_data} />
          </div>
        )}
        <Input.TextArea
          className={styles.input}
          autoSize={{ minRows: 1, maxRows: 10 }}
          placeholder={t("add_comment")}
          value={commentInput}
          onKeyDown={handleAddComment}
          onChange={({ target }) => setCommentInput(target.value)}
        />
      </div>
    </div>
  );
}
