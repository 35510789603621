import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { attachEntities } from "../../../../../../../../api/statusApi";
import toFullName from "../../../../../../../../consts/toFullName";
import { setMeetingData, setRequestData } from "../../../../../../../../store/global/actions";

import SelectEntity from "../../../../../../../../Admin/pages/components/SelectEntity/SelectEntity";
import RequestBlock from "../../../../../../../../Admin/pages/Requests/components/RequestsList/RequestBlock/RequestBlock";
import MeetingBlock from "../../../../../../Meetings/components/MeetingsList/MeetingBlock/MeetingBlock";
import AttachmentRow from "../../../../../../../../Admin/pages/components/Attachments/components/AttachmentRow";

import styles from "../../../StatusView.module.scss";
import { useDropzone } from "react-dropzone";
import { useDocuments } from "../../../../../../../../hooks/services/useDocuments";

const AttachClientDocuments = ({ idAudit, documents, disabled, values, onChange, onRemove }) => {
  const { createMultipleDocuments } = useDocuments();

  const handleAddFile = async (files) => {
    createMultipleDocuments({
      documents: files,
      type: "evidence",
      auditId: idAudit,
    });
  };

  const { getInputProps, open } = useDropzone({
    noClick: true,
    multiple: true,
    onDropAccepted: handleAddFile,
  });

  const { t } = useTranslation("dashboard", { keyPrefix: "status" });
  return (
    <div className={styles.field}>
      <label>{t("attach_document")}</label>
      <SelectEntity
        disabled={disabled}
        name={"attachedDocuments"}
        values={values}
        onChange={onChange}
        onRemove={onRemove}
        dropdownLabel={t("latest_legal_documents")}
        placeholder={t("search_document")}
        url={`/dashboard/${idAudit}/documents/`}
        options={documents}
        onCreateClick={open}
        optionComponent={(document) => (
          <AttachmentRow
            key={document.id}
            file={document}
            onClick={(e) => e?.preventDefault()}
            // onClick={cleanRequestData}
            // deleteDocument={handleRemove(value)}
          />
        )}
      />
      <input type='file' name='attachments' hidden onChange={handleAddFile} {...getInputProps()} />
    </div>
  );
};

export default AttachClientDocuments;
