import clsx from "clsx";
import { useMemo } from "react";
import { Dropdown, Button, MenuProps } from "antd";
import { useTranslation } from "react-i18next";

import { ThinChevronBottom } from "../../assets/icons";

import styles from "./StatusDropdown.module.scss";

export type StatusDropdownColors =
  | "default"
  | "blue"
  | "yellow"
  | "red"
  | "green"
  | "darkBlue"
  | "orange"
  | "purple";

export interface StatusDropdownStatus<T extends string> {
  label?: string;
  key: T;
  color: StatusDropdownColors;
  onClick?: () => void;
}

export type StatusDropdownProps<T extends string> = {
  value: T;
  onChange: (key: T) => void;
  statuses: Array<StatusDropdownStatus<T>>;
};

export default function StatusDropdown<T extends string>({
  statuses,
  value,
  onChange,
}: StatusDropdownProps<T>) {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });

  const selectedStatus = useMemo(
    () => statuses.find((status) => status.key === value) || statuses[0],
    [value, statuses]
  );

  const items: MenuProps["items"] = statuses.map((status) => ({
    key: status.key,
    label: t(status.key),
    icon: <span className={clsx(styles.statusDot, styles[status.color])} />,
  }));

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const status = statuses.find((s) => s.key === e.key);
    if (status) {
      onChange(status.key);
      status.onClick?.();
    }
  };

  return (
    <Dropdown
      menu={{ items, onClick: handleMenuClick }}
      trigger={["click"]}
      overlayClassName={styles.root}
      overlayStyle={{ minWidth: 200, zIndex: 30002 }}
    >
      <div className={styles.wrapper}>
        <Button block type='primary' className={clsx(styles.button, styles[selectedStatus.color])}>
          {t(selectedStatus.key)}
          <div className={clsx(styles.indicator, styles[selectedStatus.color])}>
            <ThinChevronBottom className={styles.indicatorIcon} />
          </div>
        </Button>
      </div>
    </Dropdown>
  );
}
