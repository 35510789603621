import React, { useMemo } from "react";
import styles from "../StatusView.module.scss";
import { useTranslation } from "react-i18next";
import { Draggable } from "react-beautiful-dnd";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  ChevronRight,
  DragIcon,
  PenIcon,
  StatusStepIndicator,
} from "../../../../../../assets/icons";
import { Button, Tag } from "../../../../../../components/ui-components";
import RequestBlock from "../../../../../../Admin/pages/Requests/components/RequestsList/RequestBlock/RequestBlock";
import MeetingBlock from "../../../../Meetings/components/MeetingsList/MeetingBlock/MeetingBlock";
import toFullName from "../../../../../../consts/toFullName";
import { setMeetingData, setRequestData } from "../../../../../../store/global/actions";
import AttachmentRow from "../../../../../../Admin/pages/components/Attachments/components/AttachmentRow";

const colorByStatus = {
  done: "#6DB333",
  not_started: "rgba(0, 62, 71, 0.4)",
  in_progress: "#49BACA",
  pending: "#E9D94A",
  blocked: "#f17f16",
  not_required: "#6167F5",
};

const dividerColorByStatus = {
  done: "#6DB333",
  in_progress: "rgba(0, 62, 71, 0.20)",
  not_started: "rgba(0, 62, 71, 0.20)",
};

const entityTypes = {
  request: ({ comments, status, name, id, type, relatedDocs, request_type }) => (
    <RequestBlock
      titleStyle={{ maxWidth: "unset" }}
      bordered
      openRequest={() => {}}
      status={status}
      commentsCount={comments?.length}
      relatedDocsCount={relatedDocs?.length}
      name={name}
      itemId={id}
      requestType={type || request_type}
      key={id}
    />
  ),
  meeting: ({ id, title, organiser, platform, date, startTime, assignedUsers, assignedRoles }) => (
    <MeetingBlock
      titleStyle={{ maxWidth: "unset" }}
      platform={platform}
      date={date}
      startTime={startTime}
      creatorFullName={toFullName(organiser)}
      assignedUsers={assignedUsers}
      assignedRoles={assignedRoles}
      openMeeting={() => {}}
      name={title}
      itemId={id}
      key={id}
      bordered
    />
  ),
  signature: (document) => <AttachmentRow key={document.id} file={document} />,
};

const StatusSteps = ({
  step,
  editMode,
  handleStepClick,
  isLast,
  idx,
  hasAdminPermissions,
  handleMarkAsDone,
  isEditDisabled,
  disableDrag,
}) => {
  const { id: idAudit } = useParams();
  const dispatch = useDispatch();
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const markAsDone = (e) => {
    e.stopPropagation();
    handleMarkAsDone(step.id);
  };

  const stepClick = () => {
    handleStepClick(step);
  };
  // const isDraggable = step.status !== "done" && step.status !== "in_progress";
  const isDraggable = true;
  const entityArrays = useMemo(
    () => ({
      request: [
        ...(step.attachedRequests || []),
        {
          ...step.attachedQuoteRequest,
          type: "quote",
        },
      ].filter((i) => i?.id),
      meeting: step.attachedMeetings,
      signature: step.attachedDocuments,
    }),
    [
      step.attachedRequests,
      step.attachedDocuments,
      step.attachedQuoteRequest,
      step.attachedMeetings,
      step.type,
    ]
  );

  const typeToActions = {
    request: {
      href: `/dashboard/${idAudit}/requests/`,
      action: setRequestData,
    },
    meeting: {
      href: `/dashboard/${idAudit}/meetings/`,
      action: setMeetingData,
    },
    signature: {
      href: `/dashboard/${idAudit}/documents/`,
    },
  };

  const CurrentEntity = useMemo(() => {
    return entityTypes[step.type];
  }, [step.type]);

  return (
    <Draggable draggableId={`status-step-${step.id}`} key={step.id} index={idx}>
      {(provided) => (
        <div
          className={`${styles.step} ${styles[step.status]}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          {!editMode && (
            <div
              style={{
                borderColor: isLast ? dividerColorByStatus[step.status] : "transparent",
              }}
              className={styles.divider}
            />
          )}
          <div className={styles.leftPane}>
            <StatusStepIndicator color={colorByStatus[step.status]} />
          </div>
          <div className={styles.stepCard} onClick={stepClick}>
            <div className={styles.stepHead}>
              <div className={styles.info}>
                {hasAdminPermissions && (
                  <span className={styles.ownership}>
                    {tGlobal(`task_type_${step.ownership}_title`)}
                  </span>
                )}
                <span className={styles.title}>{step.name}</span>
                <span
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: step.description }}
                />
                <div className={styles.actions}>
                  {step.type === "simple" && step.status === "in_progress" && (
                    <>
                      <Button handleClick={markAsDone} color={"green"} primary>
                        {tGlobal("mark_as_done")}
                      </Button>
                      {step.helpText && <span className={styles.helpText}>{step.helpText}</span>}
                    </>
                  )}
                </div>
              </div>
              <div className={styles.actions}>
                {step.status === "done" && <Tag color='green'>{tGlobal("done")}</Tag>}
                {step.status === "pending" && <Tag color='yellow'>{tGlobal("pending")}</Tag>}
                {step.status === "blocked" && <Tag color='orange'>{tGlobal("blocked")}</Tag>}
                {step.status === "not_required" && (
                  <Tag color='purple'>{tGlobal("not_required")}</Tag>
                )}
                {hasAdminPermissions &&
                  step.status !== "done" &&
                  (editMode ? (
                    step.status !== "done" && (
                      <Button secondary icon={<PenIcon />} handleClick={stepClick} color='gray' />
                    )
                  ) : (
                    <ChevronRight />
                  ))}
              </div>
            </div>
            <div className={styles.stepFooter}>
              {entityArrays[step.type]?.map((item) => {
                const url =
                  typeToActions[step.type].href + (item.type === "quote" ? "quote" : item.id);

                const onClick = () => {
                  if (typeToActions[step.type].action) {
                    dispatch(
                      typeToActions[step.type].action({
                        location: item.id,
                        type: item.type === "quote" ? "quote" : null,
                      })
                    );
                  }
                };
                return (
                  <Link key={item.id} to={url} onClick={onClick}>
                    <CurrentEntity {...item} />
                  </Link>
                );
              })}
            </div>
          </div>
          <div
            className={`${styles.dragHandle} ${editMode && !disableDrag ? styles.active : ""}`}
            {...(isDraggable && !isEditDisabled && !disableDrag ? provided.dragHandleProps : {})}
          >
            {isDraggable && <DragIcon />}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default StatusSteps;
