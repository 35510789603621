import { Tag } from "antd";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { VerificationPendingIcon, VerifiedIcon } from "../../assets/icons";

import CustomAvatar from "../CustomAvatar/CustomAvatar";
import classes from "./MemberInfo.module.scss";

interface Flag {
  label: string;
  style?: React.CSSProperties;
}

interface MemberInfoProps {
  avatarSize?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  company?: string;
  lastActive?: string;
  addedAt?: string;
  flags?: Flag[];
  hideFlags?: boolean;
  signatory?: boolean;
  signatoryStatus?: "verified" | "pending";
}

const MemberInfo: React.FC<MemberInfoProps> = ({
  avatarSize = 32,
  company,
  firstName,
  lastName,
  email,
  lastActive,
  addedAt,
  flags = [],
  hideFlags,
  signatory,
  signatoryStatus,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });
  const { t: tSettings } = useTranslation("dashboard", { keyPrefix: "settings" });

  const date = dayjs(addedAt).format("DD.MM.YYYY");
  const hasName = firstName || lastName;

  const user = {
    firstName,
    lastName,
    email,
    lastActive,
  };

  const allFlags = useMemo<Flag[]>(() => {
    const allFlags: Flag[] = [];

    if (signatory) {
      allFlags.push({ label: tSettings(`signatory_${signatory}`) });
    }
    if (flags) {
      allFlags.push(...flags);
    }
    return allFlags;
  }, [flags, signatory, signatoryStatus, tSettings]);

  return (
    <div className={classes.memberInfo}>
      <CustomAvatar width={avatarSize} height={avatarSize} user={user} />
      <div className={classes.memberName}>
        <div className={classes.memberTopRow}>
          <span className={classes.name}>
            {hasName ? `${firstName || ""} ${lastName || ""}` : email}
          </span>
          {signatoryStatus === "verified" ? (
            <VerifiedIcon />
          ) : (
            !!signatory && <VerificationPendingIcon />
          )}
          {!hideFlags &&
            allFlags?.map((flag, index) => (
              <span key={index} style={flag.style} className={classes.flag}>
                {flag.label}
              </span>
            ))}
        </div>
        {addedAt && (
          <div className={classes.date}>
            {t("added_on")} {date}
          </div>
        )}
        {company && <Tag>{company}</Tag>}
      </div>
    </div>
  );
};

export default MemberInfo;
