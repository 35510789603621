import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { attachEntities } from "../../../../../../../../api/statusApi";
import toFullName from "../../../../../../../../consts/toFullName";
import { setMeetingData, setRequestData } from "../../../../../../../../store/global/actions";

import SelectEntity from "../../../../../../../../Admin/pages/components/SelectEntity/SelectEntity";
import RequestBlock from "../../../../../../../../Admin/pages/Requests/components/RequestsList/RequestBlock/RequestBlock";
import MeetingBlock from "../../../../../../Meetings/components/MeetingsList/MeetingBlock/MeetingBlock";
import AttachmentRow from "../../../../../../../../Admin/pages/components/Attachments/components/AttachmentRow";

import styles from "../../../StatusView.module.scss";

const AttachClientMeetings = ({ idAudit, meetings, disabled, values, onChange, onRemove }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "status" });
  const meetingBaseURL = `/dashboard/${idAudit}/meetings/`;

  const handleCreateNewMeeting = () => {
    window.open(`${meetingBaseURL}new?autoclose=true`, "_blank");
  };
  return (
    <div className={styles.field}>
      <label>{t("attach_meeting")}</label>
      <SelectEntity
        disabled={disabled}
        name={"attachedMeetings"}
        values={values}
        onChange={onChange}
        onRemove={onRemove}
        dropdownLabel={t("latest_meetings")}
        placeholder={t("search_meetings")}
        url={meetingBaseURL}
        cleanUpAction={setMeetingData}
        options={meetings}
        onCreateClick={handleCreateNewMeeting}
        optionComponent={({
          id,
          title,
          organiser,
          platform,
          date,
          startTime,
          assignedUsers,
          assignedRoles,
        }) => (
          <MeetingBlock
            titleStyle={{ maxWidth: "unset" }}
            platform={platform}
            date={date}
            startTime={startTime}
            creatorFullName={toFullName(organiser)}
            assignedUsers={assignedUsers}
            assignedRoles={assignedRoles}
            openMeeting={() => {}}
            name={title}
            itemId={id}
            key={id}
            bordered
          />
        )}
      />
    </div>
  );
};

export default AttachClientMeetings;
