import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { attachEntities } from "../../../../../../../../api/statusApi";
import toFullName from "../../../../../../../../consts/toFullName";
import { setMeetingData, setRequestData } from "../../../../../../../../store/global/actions";

import SelectEntity from "../../../../../../../../Admin/pages/components/SelectEntity/SelectEntity";
import RequestBlock from "../../../../../../../../Admin/pages/Requests/components/RequestsList/RequestBlock/RequestBlock";
import MeetingBlock from "../../../../../../Meetings/components/MeetingsList/MeetingBlock/MeetingBlock";
import AttachmentRow from "../../../../../../../../Admin/pages/components/Attachments/components/AttachmentRow";

import styles from "../../../StatusView.module.scss";

const AttachClientRequests = ({
  idAudit,
  requests,
  disabled,
  openedStep,
  setOpenedStep,
  updateCurrentStepData,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "status" });
  const requestBaseURL = `/dashboard/${idAudit}/requests/`;

  const handleSelectRequest = async (name, request) => {
    if (request.type === "quote") {
      setOpenedStep({
        ...openedStep,
        attachedQuoteRequest: request,
        attachedQuoteRequestId: request.id,
      });
      updateCurrentStepData({
        attachedQuoteRequest: request,
        attachedQuoteRequestId: request.id,
      });
      await attachEntities(idAudit, openedStep.id, { attachedQuoteRequest: request.id });
    } else {
      const newRequests = [...(openedStep.attachedRequests || []), request];
      setOpenedStep({ ...openedStep, attachedRequests: newRequests });
      updateCurrentStepData({ attachedRequests: newRequests });
      await attachEntities(idAudit, openedStep.id, {
        attachedRequests: newRequests.map((i) => i.id),
      });
    }
  };

  const handleRemoveRequest = async (name, request) => {
    if (request.type === "quote") {
      setOpenedStep({ ...openedStep, attachedQuoteRequest: null, attachedQuoteRequestId: null });
      updateCurrentStepData({ attachedQuoteRequest: null, attachedQuoteRequestId: null });
      await attachEntities(idAudit, openedStep.id, { attachedQuoteRequest: null });
    } else {
      const newRequests = openedStep.attachedRequests.filter((i) => i.id !== request.id);
      setOpenedStep({
        ...openedStep,
        attachedRequests: newRequests,
      });
      updateCurrentStepData({ attachedRequests: newRequests });
      await attachEntities(idAudit, openedStep.id, {
        attachedRequests: newRequests.map((i) => i.id),
      });
    }
  };

  const handleCreateNewRequest = () => {
    window.open(`${requestBaseURL}new?autoclose=true`, "_blank");
  };

  return (
    <div className={styles.field}>
      <label>{t("attach_request")}</label>
      <SelectEntity
        disabled={disabled}
        name={"attachedRequests"}
        values={[
          ...openedStep.attachedRequests,
          { ...openedStep.attachedQuoteRequest, type: "quote" },
        ].filter((i) => i.id)}
        onChange={handleSelectRequest}
        onRemove={handleRemoveRequest}
        dropdownLabel={t("latest_requests")}
        placeholder={t("search_request")}
        url={requestBaseURL}
        cleanUpAction={setRequestData}
        options={requests}
        onCreateClick={handleCreateNewRequest}
        optionComponent={({ comments, status, name, id, type, relatedDocs, request_type }) => (
          <RequestBlock
            titleStyle={{ maxWidth: "unset" }}
            bordered
            openRequest={() => {}}
            status={status}
            commentsCount={comments?.length}
            relatedDocsCount={relatedDocs?.length}
            name={name}
            itemId={id}
            requestType={type || request_type}
            key={id}
          />
        )}
      />
    </div>
  );
};

export default AttachClientRequests;
