import { useDispatch } from "react-redux";
import { useRef, useEffect, useState, useMemo } from "react";

import { Button, Input, Label } from "../../../../components/ui-components";

import { SearchIcon, ThinChevronBottom, XMarkIcon } from "../../../../assets/icons";

import styles from "./SelectEntity.module.css";
import { Link } from "react-router-dom";

const SelectEntities = ({
  label,
  options,
  placeholder = "Select an option",
  values,
  onChange,
  onRemove,
  headerStyle,
  name,
  style,
  dropdownStyle,
  dropdownLabel,
  optionComponent,
  url,
  cleanUpAction,
  hideValues,
  disabled,
  onCreateClick,
}) => {
  const dispatch = useDispatch();
  const toggleContainer = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("click", onClickOutside);
    return () => {
      document.removeEventListener("click", onClickOutside);
    };
  }, []);

  const onClickOutside = (event) => {
    if (toggleContainer.current && !toggleContainer.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleOptionClick = (option) => {
    console.log({ option, onChange });
    if (onChange) {
      onChange(name, option);
      setIsOpen(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          [option.label, option.name, option.title].some((i) =>
            i?.toLowerCase().includes(searchValue.toLowerCase())
          ) && !values.some((i) => i.id === option.id)
      ),
    [options, searchValue, values]
  );

  const handleRemove = (value) => (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (disabled) return;
    onRemove(name, value);
  };

  return (
    <div className={`${styles.root} ${disabled ? styles.disabled : ""}`}>
      {label && <Label>{label}</Label>}
      <div className={styles.dropdownContainer} ref={toggleContainer} style={style}>
        <div
          className={`${styles.dropdownHeader} ${isOpen ? styles.open : ""}`}
          onClick={() => setIsOpen(!isOpen)}
          style={headerStyle}
        >
          <SearchIcon className={styles.searchIcon} />
          <Input
            disabled={disabled}
            value={searchValue}
            onChange={handleSearchChange}
            placeholder={placeholder}
          />
          <ThinChevronBottom className={styles.chevron} />
        </div>
        {isOpen && (
          <ul style={dropdownStyle} className={styles.dropdown}>
            {dropdownLabel && <label>{dropdownLabel}</label>}
            <div className={styles.dropdownList}>
              {filteredOptions.map((option, index) => (
                <li
                  key={index}
                  onClick={() => handleOptionClick(option)}
                  className={styles.dropdownListItem}
                  style={option.style}
                >
                  {optionComponent(option)}
                </li>
              ))}
            </div>
            {onCreateClick && (
              <Button fullWidth handleClick={onCreateClick} color={"blue"} secondary>
                Create new
              </Button>
            )}
          </ul>
        )}
      </div>
      <div className={styles.selectedValues}>
        {!hideValues &&
          values.map((value) => {
            const href = url + (value.type === "quote" ? "quote" : value.id);
            const cleanRequestData = () =>
              cleanUpAction &&
              dispatch(
                cleanUpAction({ location: value.id, type: value.type === "quote" ? "quote" : null })
              );

            return (
              <Link
                to={href}
                onClick={cleanRequestData}
                key={value.id}
                className={styles.selectedValue}
              >
                {optionComponent(value)}
                <XMarkIcon onClick={handleRemove(value)} />
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default SelectEntities;
