import { useEffect } from "react";
import { socket } from "../../utils/socket";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

const syncEvents = ["document", "request", "meeting"];

const getEntityName = (entity) => {
  return entity + "s";
};

const SyncAuditData = ({ children }) => {
  const queryClient = useQueryClient();
  const { id } = useParams();

  useEffect(() => {
    if (!socket.connected) {
      socket.once("connect", () => {
        console.log("socket reconnected?");
      });
    }
  }, [id]);

  useEffect(() => {
    if (!id) return;
    console.log("invalidateQueries");
    for (const entity of syncEvents) {
      queryClient.invalidateQueries([getEntityName(entity)]);
    }
  }, [id]);

  useEffect(() => {
    if (!queryClient) return;

    const onEntitySync = (entity) => (res) => {
      console.log("onEntitySync", entity, res);
      const entityName = getEntityName(entity);
      let queryData = queryClient.getQueryData([entityName, id]);

      if (!queryData) {
        queryData = queryClient.getQueryData([entityName]);
      }

      const data = Array.isArray(queryData) ? queryData : queryData?.[entityName];
      const context = res?.context;

      if (!data || !context?.action) {
        return queryClient.invalidateQueries({
          queryKey: [entityName],
          refetchType: "all",
        });
      }

      if (context.action === "create") {
        const existingEntity = data.find((el) => el.id === context.id);

        if (!existingEntity) {
          return queryClient.invalidateQueries({
            queryKey: [entityName],
            refetchType: "all",
          });
        }
      }
    };

    for (const entity of syncEvents) {
      socket.on(entity, onEntitySync(entity));
    }

    return () => {
      for (const entity of syncEvents) {
        socket.off(entity, onEntitySync(entity));
      }
    };
  }, []);

  return children;
};

export default SyncAuditData;
