import ClientContent from "./ClientContent/ClientContent";
import AuditorContent from "./AuditorContent";

const ContentByType = (props) => {
  const updateCurrentStepData = (toUpdate) => {
    const { openedStep, setData, setOpenedStep } = props;
    const isSubtask = !!openedStep.parentTaskId;
    setOpenedStep((state) => {
      if (!state) return;
      return {
        ...state,
        ...toUpdate,
      };
    });
    setData((state) => ({
      ...state,
      audit: {
        ...state.audit,
        statusGroups: state.audit.statusGroups.map((group) => ({
          ...group,
          steps: group.steps.map((step) => {
            if (isSubtask) {
              if (step.id === openedStep.parentTaskId) {
                return {
                  ...step,
                  subtasks: step.subtasks?.map((subtask) => {
                    if (subtask.id === openedStep.id) {
                      return {
                        ...subtask,
                        ...toUpdate,
                      };
                    }
                    return subtask;
                  }),
                };
              }
              return step;
            }

            if (step.id === openedStep.id) {
              return {
                ...step,
                ...toUpdate,
              };
            }
            return step;
          }),
        })),
      },
    }));
  };

  const childProps = {
    ...props,
    updateCurrentStepData,
  };
  switch (props.openedStep.ownership) {
    case "client":
      return <ClientContent {...childProps} />;
    case "auditor":
      return <AuditorContent {...childProps} />;
    default:
      return null;
  }
};

export default ContentByType;
