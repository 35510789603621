import cloneDeep from "lodash/cloneDeep";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { deleteMeeting, getMeetings } from "../../../api/meetingsAPI";
import { PATH } from "../../../consts/constants";
import { useFetch } from "../../../hooks";
import { setMeetingData } from "../../../store/global/actions";
import { REQUEST_AND_MEETING_LOCATION } from "../../../store/global/reducer";

import { Loading } from "../../../components/ui-components";
import { MeetingsList, MeetingView, NewMeeting } from "./components";
import { DualScrollWrapper } from "../../../Admin/pages/components";
import EmptyState from "../../../components/EmptyState";

import styles from "./Meetings.module.scss";
import { useQuery } from "@tanstack/react-query";

const Meetings = () => {
  const { meetingId, id: idAudit } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "meetings" });

  const { isLoading, data, refetch } = useQuery({
    queryKey: ["meetings", "grouped", idAudit],
    queryFn: () => getMeetings(idAudit),
  });

  const { meetingData } = useSelector((state) => state.global);
  const userRole = useSelector((state) => state.user.user_role);
  const showNewMeetingPage = meetingId === PATH.EDIT || meetingId === PATH.NEW;
  const [countRefetch, setCountRefetch] = useState(0);
  const [firstMeetingID, setFirstMeetingID] = useState(0);
  const [meetings, setMeetings] = useState({});
  const [isDiscarded, setIsDiscarded] = useState(false);
  const [leftWrapperVisible, setLeftWrapperVisible] = useState(false);
  const { hasAdminPermissions, role } = userRole || {};
  const URLParams = new URLSearchParams(window.location.search);
  const autoclose = URLParams.get("autoclose");

  const triggerRequest = () => {
    setCountRefetch((prev) => prev + 1);
  };

  useEffect(() => {
    if (data && !isLoading) {
      const allMeetings = [
        ...data?.meetings["Upcoming Meetings"],
        ...data?.meetings["Past Meetings"],
      ];
      const firstMeetingID = allMeetings[0]?.id;
      setMeetings(data.meetings);

      if (autoclose && meetingId === PATH.NEW) {
        return;
      }

      setFirstMeetingID(firstMeetingID);
      handleChangeLocation({
        meetingId: firstMeetingID,
      });
    }
    // eslint-disable-next-line
  }, [data, isLoading]);

  useEffect(() => {
    refetch();
  }, [countRefetch]);

  const handleChangeLocation = ({ meetingId }) => {
    if (meetingData.location === REQUEST_AND_MEETING_LOCATION.DEFAULT) {
      if (meetingId) {
        const meetingLocation = `/dashboard/${idAudit}/meetings/${meetingId}`;

        dispatch(setMeetingData({ location: meetingLocation }));
        history.push(meetingLocation);
      } else {
        const meetingLocation = `/dashboard/${idAudit}/meetings/new`;
        dispatch(setMeetingData({ location: "new" }));
        history.push(meetingLocation);
      }
      return;
    }
    if (meetingData.location === REQUEST_AND_MEETING_LOCATION.NEW) {
      const meetingLocation = `/dashboard/${idAudit}/meetings/new`;
      dispatch(setMeetingData({ location: "new" }));
      history.push(meetingLocation);
      return;
    }
    if (meetingData.location === REQUEST_AND_MEETING_LOCATION.EDIT) {
      const meetingLocation = `/dashboard/${idAudit}/meetings/edit`;
      dispatch(setMeetingData({ location: "edit" }));
      history.push(meetingLocation, meetingData.data);
    } else {
      history.push(meetingData.location);
    }
  };

  useEffect(() => {
    if (data && !isLoading) {
      dispatch(setMeetingData({ location: meetingId }));
    }
    // eslint-disable-next-line
  }, [meetingId]);

  useEffect(() => {
    if (state) {
      dispatch(setMeetingData({ location: "edit", data: state }));
    }
    // eslint-disable-next-line
  }, [state]);

  const handleRedirectLastMeeting = () => {
    if (firstMeetingID) {
      history.push(`/dashboard/${idAudit}/meetings/${firstMeetingID}`);
    }
  };

  const handleUpdateMeetingData = (savedData) => {
    dispatch(setMeetingData(savedData));
  };

  const changeCurrentMeeting = (meetingId, updateObj) => {
    setMeetings((prev) => {
      return Object.entries(prev).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]: value.map((meeting) => {
            if (meeting.id === +meetingId) {
              return {
                ...meeting,
                ...updateObj,
              };
            }
            return meeting;
          }),
        };
      }, {});
    });
  };

  const handleDelete = () => {
    deleteMeeting(idAudit, meetingId).then(() => {
      const updatedData = {};
      const data = cloneDeep(meetings);

      Object.keys(data).forEach((key) => {
        updatedData[key] = data[key].filter((item) => item.id !== +meetingId);
      });
      setMeetings(updatedData);
      setFirstMeetingID(
        [...updatedData["Upcoming Meetings"], ...updatedData["Past Meetings"]][0]?.id
      );
      handleRedirectLastMeeting();
      setCountRefetch((prev) => prev + 1);
    });
  };

  // TODO: add createMeeting to the list of permissions and then remove meetingAccess here:
  const canCreate = hasAdminPermissions || role?.meetingAccess || role?.createMeetings || false;
  return (
    <div className={styles.wrapper}>
      {!isLoading ? (
        firstMeetingID || !meetingData?.isEmpty || leftWrapperVisible || autoclose ? (
          <DualScrollWrapper
            leftSidebar={
              <MeetingsList
                isDiscarded={isDiscarded}
                setIsDiscarded={setIsDiscarded}
                meetings={meetings}
                isLoading={isLoading}
                canCreate={canCreate}
              />
            }
          >
            {showNewMeetingPage
              ? ((meetingId === PATH.NEW || meetingId === PATH.EDIT) && isDiscarded) || (
                  <NewMeeting
                    editData={state}
                    setIsDiscarded={setIsDiscarded}
                    handleRedirectLastMeeting={handleRedirectLastMeeting}
                    lastChanges={meetingData.data}
                    triggerRefetch={triggerRequest}
                    meetingId={meetingId}
                    handleUpdateMeetingData={handleUpdateMeetingData}
                    change
                  />
                )
              : meetingId && (
                  <MeetingView
                    onChangeCurrentMeeting={changeCurrentMeeting}
                    onDelete={handleDelete}
                  />
                )}
          </DualScrollWrapper>
        ) : (
          <EmptyState
            title={t("no_meetings_planned")}
            description={t("no_meetings_planned_description")}
            buttonText={t("create_meeting")}
            onButtonClick={() => {
              dispatch(setMeetingData({ isEmpty: false }));
              setLeftWrapperVisible(true);
            }}
          />
        )
      ) : (
        <Loading position='absoluteCenter' />
      )}
    </div>
  );
};

export default Meetings;
