import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import VerificationInput from "react-verification-input";

import { verifySmS, sendSmS } from "../../api/phoneApi";
import Keys from "../../consts/helper";
import { setPlatformRole, setUserData, setUserToken } from "../../store/user/actions";

import Button from "../../components/ui-components/Button/Button";

import logo from "../../assets/images/logo-primary.svg";

import "./loginPhoneSms.css";

const LoginPhoneSms = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("dashboard", { keyPrefix: "login" });

  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const userData = JSON.parse(localStorage.getItem(Keys.USER_DATA));

  const onSubmit = () => {
    sendSmS(
      {
        phone: userData.phone,
      },
      userData?.token
    )
      .then(() => {
        history.push("/login/phone/double_sms");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const onSubmitNext = (e) => {
    e?.preventDefault();
    if (value.length === 6) {
      setLoading(true);
      verifySmS(
        {
          code: value,
        },
        userData?.token
      )
        .then((res) => {
          if (res?.response?.data?.message === "Codes do not match") {
            setError(res.response.data.message);
          } else if (res.success) {
            dispatch(setUserToken(res.token));
            dispatch(setUserData(res));
            dispatch(setPlatformRole(res.platformRole));
            const redirectTo = localStorage.getItem(Keys.LAST_VISITED_PAGE);
            const prevTo = localStorage.getItem(Keys.PREVIOUS_VISITED_PAGE);
            history.push(redirectTo || "/audits");
            if (redirectTo) {
              localStorage.setItem(Keys.PREVIOUS_VISITED_PAGE, redirectTo);
              localStorage.removeItem(Keys.LAST_VISITED_PAGE);
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (value.length === 6) {
      onSubmitNext();
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <div className={"wrapper_login_page"}>
      <div className={"wrapper_login_content"}>
        <Link to='/'>
          <img src={logo} alt='logo' className='initialLogo' />
        </Link>

        <form onSubmit={onSubmitNext} className={"login_form code_form"}>
          <p className={"login_form__title"}>{t("almost_there")}</p>
          <p className={"login_form__subtitle"}>{t("enter_code")}</p>

          <div className={"login_sms_form"}>
            <div className='login_sms-list'>
              <VerificationInput
                validChars='A-Za-z0-9'
                placeholder=''
                autoFocus
                onChange={(target) => {
                  setValue(target);
                  setError(false);
                }}
              />
            </div>
            {error && <div className='error-codes-sms'>{error}</div>}

            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Button primary large disabled={value.length !== 6} color={"blue"} type={"submit"}>
                  {loading ? (
                    <span className='loader small'></span>
                  ) : value.length > 5 ? (
                    t("send")
                  ) : (
                    `${6 - value.length} ${t("digits_left")}`
                  )}
                </Button>
              </Col>
              <Col span={24}>
                <Button onClick={onSubmit} secondary large color={"blue"}>
                  {t("resend_code")}
                </Button>
              </Col>
              <Col span={24}>
                <Link to='/login/phone'>
                  <Button tertiary color='blue' large>
                    Change phone number
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPhoneSms;
