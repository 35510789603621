export enum SignatoryStatus {
  pending = "pending",
  verified = "verified",
  rejected = "rejected",
}

export enum TeamType {
  audit_firm = "audit_firm",
  fiduciary = "fiduciary",
}

export enum NotificationType {
  status_update = "status_update",
  document_upload = "document_upload",
  comment_added = "comment_added",
  comment_mention = "comment_mention",
  request_created = "request_created",
}

export enum NotificationAction {
  read = "read",
  click = "click",
}

export enum DocumentSignaturesStatus {
  failed = "failed",
  unsigned = "unsigned",
  in_progress = "in_progress",
  signed = "signed",
}

export enum CompanyMemberRole {
  auditor = "auditor",
  main_admin = "main_admin",
  admin = "admin",
  member = "member",
}

export enum CompanyMemberSignatory {
  sole = "sole",
  joint = "joint",
}

export enum StatusStepType {
  simple = "simple",
  request = "request",
  signature = "signature",
  meeting = "meeting",
  inquiry = "inquiry",
  analytics = "analytics",
  test_of_details = "test_of_details",
  general = "general",
}

export enum StatusStepOwnership {
  client = "client",
  auditor = "auditor",
}

export enum StepStatus {
  not_started = "not_started",
  in_progress = "in_progress",
  pending = "pending",
  done = "done",
  not_required = "not_required",
}

export enum AuditTeamMemberRole {
  lead_auditor = "lead_auditor",
  auditor = "auditor",
  quality_assurance = "quality_assurance",
  quality_review = "quality_review",
}

export enum ProvideInformation {
  manual = "manual",
  upload = "upload",
  auto = "auto",
}

export enum RequestType {
  file_request = "file_request",
  data_transfer_request = "data_transfer_request",
}

export enum DocumentType {
  legal = "legal",
  shared = "shared",
  evidence = "evidence",
}

export enum DocumentStatus {
  missing_signature = "missing_signature",
  in_progress = "in_progress",
  signed = "signed",
}
