import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Card } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAllUsers } from "../../../../api/userApi";
import { changeTeamSignatory } from "../../../../api/teamApi";
import {
  changeMemberRole,
  inviteCompanyMember,
  removeCompanyMember,
} from "../../../../api/companyApi";
import { COMPANY_ROLES } from "../../../../consts/company";

import MemberInfo from "../../../../components/MemberRow/MemberInfo";
import InviteForm from "../../../../components/InviteForm";
import MemberActions from "../../../../components/MemberRow/MemberActions";

import classes from "../../Company/CompanySettings/Cards/Team/Team.module.scss";

const initInvite = {
  email: "",
  role: "member",
};

const AccessControl = ({
  companyData,
  hideMenu,
  customInvite,
  customChangeRole,
  customRemoveMember,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });

  const [members, setMembers] = useState(companyData.members || []);
  const [invite, setInvite] = useState(initInvite);
  const [shownMenu, setShowMenu] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [error, setError] = useState("");
  const [myMember, setMyMember] = useState(null);

  const user = useSelector((state) => state.user.user_data);

  const memberRoles = [
    {
      label: t("member"),
      value: "member",
    },
    {
      label: t("admin"),
      value: "admin",
    },
    {
      label: t("remove_user"),
      value: "remove",
      caution: true,
    },
  ];

  const inviteRoles = [
    {
      label: t("member"),
      value: "member",
      disclaimer: "access_description",
    },
    {
      label: t("admin"),
      value: "admin",
      disclaimer: "admin_description",
      disclaimerColor: "#F17F16",
    },
  ];

  const handleGetUsers = async () => {
    const users = await getAllUsers();
    const membersEmails = members.map((member) => {
      if (member.user.email === user.email) setMyMember(member);
      return member.user.email;
    });
    setUsers(users.filter((user) => !membersEmails.includes(user.email)));
  };

  useEffect(() => {
    handleGetUsers();
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(users);
    }
  }, [users]);

  const inputData = (key, value) => {
    setInvite((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const inviteMember = async () => {
    try {
      let member;

      if (customInvite) {
        member = await customInvite(invite);
      } else {
        member = await inviteCompanyMember(companyData.id, invite);
      }

      setMembers((state) => [...state, member]);
      setUsers((state) => state.filter((user) => user.email !== invite.email));
      setInvite(initInvite);
    } catch (error) {
      console.log(error);
      setError(error?.response?.data);
    }
  };

  const changeSignatory = async (value, memberId) => {
    try {
      const signatory = value || null;
      const res = await changeTeamSignatory(memberId, { signatory });
      const newMember = res.teamMember || {};
      setMembers((state) =>
        state.map((item) =>
          item.id === memberId ? { ...item, ...newMember, role: newMember?.role?.name } : item
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const changeRole = async (id, role) => {
    try {
      if (role === "remove") {
        if (customRemoveMember) {
          await customRemoveMember(id);
        } else {
          await removeCompanyMember(companyData.id, id);
        }
        setMembers((state) => state.filter((item) => item.id !== id));
        return;
      }

      let member;
      if (customChangeRole) {
        member = await customChangeRole(id, role);
      } else {
        member = await changeMemberRole(companyData.id, id, { role });
      }
      setMembers((state) => state.map((item) => (item.id === id ? member.teamMember : item)));
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeInvite = ({ target }) => {
    const inputEmail = target.value;
    inputData("email", inputEmail);
    const filtered = users.filter(
      (user) =>
        user.email.toLowerCase().includes(inputEmail.toLowerCase()) ||
        user.firstName.toLowerCase().includes(inputEmail.toLowerCase()) ||
        user.lastName.toLowerCase().includes(inputEmail.toLowerCase())
    );
    setFilteredUsers(filtered);
    setShowMenu(true);
  };

  // TODO: ask whether auditor can choose another auditor or main admin can choose another main admin
  const roles = useMemo(() => {
    return memberRoles;
  }, [user]);
  const activeRole = inviteRoles.find((role) => role.value === invite.role);

  const handleClickUser = (email) => {
    inputData("email", email);
    setShowMenu(false);
    setFilteredUsers(users);
  };
  const hasAdminRights = myMember?.role === "admin" || user?.role === "super-admin";
  const adminAmount = members.filter((member) => member.role === "admin").length;

  return (
    <>
      {hasAdminRights && (
        <InviteForm
          placeholder={t("invite_company")}
          allowMenu={shownMenu && !hideMenu}
          handleClickUser={handleClickUser}
          filteredUsers={filteredUsers}
          invite={invite}
          inviteRoles={inviteRoles}
          inputData={inputData}
          inviteMember={inviteMember}
          onChangeInvite={onChangeInvite}
          activeRole={activeRole}
          error={error}
          setError={setError}
        />
      )}
      {members
        .sort((a, b) => b.id - a.id)
        .map((member) => {
          const { id, firstName, lastName, email, lastActive, signatoryStatus } = member.user;
          const role = member.role;
          const memberGridClasses = clsx(classes.grid, classes.memberGrid, {
            [classes.highlighted]: id === error?.userId,
          });
          const hasFlag = role === COMPANY_ROLES.MAIN_ADMIN || role === COMPANY_ROLES.AUDITOR;
          const flags = hasFlag ? [{ label: role.split("_").join(" ") }] : [];
          const isAdmin = role === COMPANY_ROLES.ADMIN;
          return (
            <Card.Grid hoverable={false} className={memberGridClasses}>
              <div className={classes.memberRow}>
                <MemberInfo
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  addedAt={member.addedAt}
                  flags={flags}
                  lastActive={lastActive}
                  signatory={member.signatory}
                  signatoryStatus={signatoryStatus}
                />

                <MemberActions
                  allowEdit={hasAdminRights && (!isAdmin || adminAmount > 1)}
                  role={role}
                  tRole={t(`role_${role}`)}
                  roles={roles}
                  member={member}
                  onChangeRole={changeRole}
                  onChangeSignatory={changeSignatory}
                />
              </div>
            </Card.Grid>
          );
        })}
    </>
  );
};

export default AccessControl;
