import React from "react";
import { attachEntities } from "../../../../../../../../api/statusApi";
import AttachClientDocuments from "./AttachDocuments";
import AttachClientMeetings from "./AttachMeetings";
import AttachClientRequests from "./AttachRequests";

const ClientContent = ({
  openedStep,
  setOpenedStep,
  idAudit,
  requests,
  meetings,
  documents,
  updateCurrentStepData,
}) => {
  const handleSelectEntity = async (name, entity) => {
    console.log({ name, entity });
    const newEntities = [...openedStep[name], entity];
    setOpenedStep({ ...openedStep, [name]: newEntities });
    updateCurrentStepData({ [name]: newEntities });
    await attachEntities(idAudit, openedStep.id, { [name]: newEntities.map((i) => i.id) });
  };

  const handleRemoveEntity = async (name, entity) => {
    const newEntities = openedStep[name].filter((i) => i.id !== entity.id);
    setOpenedStep({ ...openedStep, [name]: newEntities });
    updateCurrentStepData({ [name]: newEntities });
    await attachEntities(idAudit, openedStep.id, { [name]: newEntities.map((i) => i.id) });
  };

  const sharedProps = {
    idAudit,
    disabled: openedStep.status === "done",
  };
  console.log({ meetings });
  return (
    <>
      {openedStep.type === "request" && (
        <AttachClientRequests
          requests={requests}
          openedStep={openedStep}
          setOpenedStep={setOpenedStep}
          updateCurrentStepData={updateCurrentStepData}
          {...sharedProps}
        />
      )}
      {openedStep.type === "meeting" && (
        <AttachClientMeetings
          meetings={meetings}
          values={openedStep.attachedMeetings || []}
          onChange={handleSelectEntity}
          onRemove={handleRemoveEntity}
          {...sharedProps}
        />
      )}
      {openedStep.type === "signature" && (
        <AttachClientDocuments
          values={openedStep.attachedDocuments}
          documents={documents}
          onChange={handleSelectEntity}
          onRemove={handleRemoveEntity}
          {...sharedProps}
        />
      )}
    </>
  );
};

export default ClientContent;
