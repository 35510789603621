import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { getRequests } from "../../../api/requestsPage";
import { useFetch } from "../../../hooks";
import { PATH } from "../../../consts/constants";
import { REQUEST_STATUS_ORDERED, REQUEST_STATUSES } from "../../../consts/enums";
import { setRequestData } from "../../../store/global/actions";
import { REQUEST_AND_MEETING_LOCATION } from "../../../store/global/reducer";

import { RequestsList, NewRequest, RequestView } from "./components";
import { DualScrollWrapper } from "../components";
import { Button, Loading } from "../../../components/ui-components";
import QuoteRequestView from "./components/QuoteRequestView/QuoteRequestView";

import styles from "./Requests.module.scss";
import { useQuery } from "@tanstack/react-query";

const initStatusFilters = Object.values(REQUEST_STATUSES);

const Request = () => {
  const { requestId, id: idAudit } = useParams();
  const { state } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("dashboard", { keyPrefix: "requests" });

  const userRole = useSelector((state) => state.user.user_role);
  const { role, hasAdminPermissions } = userRole || {};
  const initStatus = state?.status;
  const [countRefetch, setCountRefetch] = useState(0);
  const [isDiscarded, setIsDiscarded] = useState(false);
  const { requestData } = useSelector((state) => state.global);
  const [requests, setRequests] = useState([]);
  const [leftWrapperVisible, setLeftWrapperVisible] = useState(false);
  const [statusFilters, setStatusFilters] = useState(initStatus ? [initStatus] : initStatusFilters);
  const [countRequestsStatus, setCountRequestsStatus] = useState({
    [REQUEST_STATUSES.REQUESTED]: 0,
    [REQUEST_STATUSES.SENT]: 0,
    [REQUEST_STATUSES.APPROVED]: 0,
  });
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["requests", idAudit],
    queryFn: () => getRequests(idAudit, statusFilters),
  });

  const triggerRequest = () => {
    setCountRefetch((prev) => prev + 1);
  };

  const redirect = (path) => {
    history.push({
      pathname: path,
      search: history.location.search,
    });
  };

  const handleDeleteAction = () => {
    const data = [...requests];
    const index = data.findIndex((request) => request.id === +requestId);
    data.splice(index, 1);
    setRequests(data);

    if (data[0].id) {
      const requestLocation = `/dashboard/${idAudit}/requests/${data[0].id}`;
      dispatch(setRequestData({ location: requestLocation }));
      redirect(requestLocation);
    }
  };

  useEffect(() => {
    if (data && !isLoading) {
      dispatch(setRequestData({ location: requestId }));
    }
    // eslint-disable-next-line
  }, [requestId]);

  const handleChangeLocation = ({ requestId, type }) => {
    console.log({ requestId, type });
    if (type) {
      const requestLocation = `/dashboard/${idAudit}/requests/${type}`;

      dispatch(setRequestData({ location: requestLocation }));
      redirect(requestLocation);
      return;
    }
    if (requestData.location === REQUEST_AND_MEETING_LOCATION.DEFAULT) {
      if (requestId && requestId !== "0") {
        const requestLocation = `/dashboard/${idAudit}/requests/${requestId}`;

        dispatch(setRequestData({ location: requestLocation }));
        redirect(requestLocation);
      } else {
        const requestLocation = `/dashboard/${idAudit}/requests/new`;
        dispatch(setRequestData({ location: "new" }));
        redirect(requestLocation);
      }
      return;
    }
    if (requestData.location === REQUEST_AND_MEETING_LOCATION.NEW) {
      const requestLocation = `/dashboard/${idAudit}/requests/new`;
      dispatch(setRequestData({ location: "new" }));
      redirect(requestLocation);
      return;
    }
    if (requestData.location === REQUEST_AND_MEETING_LOCATION.EDIT) {
      const requestLocation = `/dashboard/${idAudit}/requests/edit`;
      dispatch(setRequestData({ location: "edit" }));
      redirect(requestLocation, requestData.data);
    } else {
      redirect(requestData.location);
    }
  };

  useEffect(() => {
    if (state && Object.keys(state).length === 1 && state.status) {
      setStatusFilters([state.status]);
    } else if (state) {
      dispatch(setRequestData({ location: "edit", data: state }));
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (data && !isLoading) {
      setRequests(data.requests);
      setCountRequestsStatus((prevValue) => ({
        ...prevValue,
        ...data.status,
      }));

      const URLParams = new URLSearchParams(window.location.search);
      const autoclose = URLParams.get("autoclose");

      if (autoclose && requestId === "new") {
        return;
      }

      if (!requestId || requestId === "0" || (data.requests.length > 0 && requestId === "new")) {
        const { id, type } = data.requests[0] || {};
        handleChangeLocation({ requestId: id, type });
      } else {
        handleChangeLocation({
          requestId: +requestId,
          type: requestId === "quote" ? "quote" : null,
        });
      }
    }
    // eslint-disable-next-line
  }, [data, isLoading]);

  useEffect(() => {
    refetch();
  }, [countRefetch]);

  const handleUpdateRequestData = (savedData) => {
    dispatch(setRequestData(savedData));
  };

  const handleRedirectLastRequest = () => {
    if (requests.length > 0) {
      const { id, type } = requests[0];
      if (!type) {
        return redirect(`/dashboard/${idAudit}/requests/${id}`);
      }

      return redirect(`/dashboard/${idAudit}/requests/${type}`);
    }
  };

  const changeCurrentRequest = (requestId, updateObj, fromStatus) => {
    if (
      updateObj.hasOwnProperty("status") &&
      REQUEST_STATUSES.hasOwnProperty(updateObj.status.toUpperCase())
    ) {
      if (fromStatus) {
        setCountRequestsStatus((prevValue) => {
          if (prevValue[fromStatus] > 0) {
            return {
              ...prevValue,
              [fromStatus]: prevValue[fromStatus] - 1,
              [updateObj.status]: prevValue[updateObj.status] + 1,
            };
          }
        });
      } else {
        const curIdx = REQUEST_STATUS_ORDERED.indexOf(updateObj.status);
        setCountRequestsStatus((prevValue) => {
          const toUpdate = {
            ...prevValue,
            [updateObj.status]: prevValue[updateObj.status] + 1,
          };
          if (curIdx > 0) {
            toUpdate[REQUEST_STATUS_ORDERED[curIdx - 1]] -= 1;
          }
          return toUpdate;
        });
      }
    }

    setRequests((prev) => {
      return prev.map((request) => {
        if (request.type === requestId || request.id === +requestId) {
          return {
            ...request,
            ...updateObj,
          };
        }
        return request;
      });
    });
  };

  const showNewRequestPage = requestId === PATH.EDIT || requestId === PATH.NEW;

  const _renderEmptyState = () => (
    <div className='empty_text' style={{ marginTop: -27, paddingTop: 200 }}>
      {hasAdminPermissions ? (
        <>
          <span className='title'>{t("no_request_created")}</span>
          <span className='subtitle'>{t("no_request_created_description")}</span>
          <Button
            onClick={() => {
              dispatch(setRequestData({ isEmpty: false }));
              setLeftWrapperVisible(true);
            }}
            primary
            style={{ width: 220, marginTop: 40 }}
            large
            color={"blue"}
          >
            {t("create_request")}
          </Button>
        </>
      ) : (
        <>
          <span className='title'>{t("no_request_created")}</span>
          <span className='subtitle'>{t("no_request_auditor")}</span>
        </>
      )}
    </div>
  );

  const canCreate = hasAdminPermissions || role?.createRequests || false;
  const hasFiltersChanged = statusFilters.length !== initStatusFilters.length;
  const isQuoteRequest = requestId === "quote";

  return (
    <div className={styles.wrapper}>
      {userRole && !isLoading ? (
        requests.length !== 0 ||
        !requestData?.isEmpty ||
        leftWrapperVisible ||
        hasFiltersChanged ? (
          <DualScrollWrapper
            leftSidebar={
              <RequestsList
                isDiscarded={isDiscarded}
                setIsDiscarded={setIsDiscarded}
                requests={requests}
                setRequests={setRequests}
                isLoading={isLoading}
                countRequestsStatus={countRequestsStatus}
                statusFilters={statusFilters}
                setStatusFilters={setStatusFilters}
                canCreate={canCreate}
              />
            }
          >
            {showNewRequestPage
              ? ((requestId === PATH.NEW || requestId === PATH.EDIT) && isDiscarded) || (
                  <NewRequest
                    setIsDiscarded={setIsDiscarded}
                    editData={state}
                    handleRedirectLastRequest={handleRedirectLastRequest}
                    lastChanges={requestData.data}
                    triggerRefetch={triggerRequest}
                    requestId={requestId}
                    handleUpdateRequestData={handleUpdateRequestData}
                  />
                )
              : requestId &&
                (isQuoteRequest ? (
                  <QuoteRequestView
                    triggerRefetch={triggerRequest}
                    changeCurrentRequest={changeCurrentRequest}
                    hasAdminPermissions={hasAdminPermissions}
                  />
                ) : (
                  <RequestView
                    triggerRefetch={triggerRequest}
                    handleDeleteAction={handleDeleteAction}
                    changeCurrentRequest={changeCurrentRequest}
                    hasAdminPermissions={hasAdminPermissions}
                  />
                ))}
          </DualScrollWrapper>
        ) : (
          _renderEmptyState()
        )
      ) : (
        <Loading position='absoluteCenter' />
      )}
    </div>
  );
};

export default Request;
