import { Dropdown, Input, Progress } from "antd";
import clsx from "clsx";
import React, { ChangeEvent, SyntheticEvent, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
// @ts-ignore
import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import EditButtons from "../../../../../../Admin/pages/components/EditButtons";

import { DragIcon, StepGroupFinishedIcon, ThreeDotsIcon } from "../../../../../../assets/icons";
import DeleteModal from "../../../../../../components/DeleteModal";
import { Button } from "../../../../../../components/ui-components";
import { StatusGroup } from "../../../../../../types/status";
import styles from "./StatusGroupItem.module.scss";
import useOutsideClick from "../../../../../../hooks/useOutsideClick";
import { isTaskDone } from "../../../../../../helpers/isTaskDone";

type Props = {
  item: StatusGroup;
  editMode: boolean;
  index: number;
  activeGroup: number;
  statusGroupAmount: number;
  onClick: (id: number) => void;
  changeGroupName: (index: number, name: string) => void;
  duplicateGroup: (index: number) => void;
  deleteGroup: (index: number) => void;
  isUnsynced?: boolean;
};

export default function StatusGroupItem({
  activeGroup,
  editMode,
  item,
  index,
  statusGroupAmount,
  onClick,
  changeGroupName,
  duplicateGroup,
  deleteGroup,
  isUnsynced,
}: Props) {
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const renameRef = React.createRef<HTMLFormElement>();

  const [currentRename, setCurrentRename] = useState(
    item.editModeAction === "create" ? "empty" : ""
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeletePopup] = useState(false);

  const isActiveGroup = item.id === activeGroup && item.editModeAction !== "duplicate";
  const groupNumber = index + 1;

  const filteredSteps = useMemo(
    () =>
      item.steps
        ?.filter((step) => step.editModeAction !== "delete")
        ?.sort((a, b) => a.order - b.order),
    [item.steps]
  );
  const subStepsFinishedAmount = useMemo(
    () => filteredSteps?.filter(isTaskDone).length,
    [filteredSteps]
  );
  const subStepsAmount = filteredSteps?.length || 0;
  const totalProgress =
    subStepsAmount > 0 && subStepsFinishedAmount
      ? (subStepsFinishedAmount / subStepsAmount) * 100
      : 0;

  const actionItems = useMemo(
    () => [
      {
        key: "0",
        label: tGlobal("rename"),
        onClick: () => setCurrentRename(item.name || "empty"),
      },
      {
        key: "1",
        label: tGlobal("duplicate"),
        onClick: () => duplicateGroup(index),
      },
      {
        key: "2",
        label: tGlobal("delete"),
        danger: true,
        onClick: () => setConfirmDeletePopup(true),
      },
    ],
    [tGlobal, item.name]
  );

  const handleClick = () => {
    if (!item.id || item.editModeAction === "duplicate" || item.editModeAction === "create") return;
    if (currentRename) return;
    onClick(item.id);
  };

  const handleCancelRename = (e?: SyntheticEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    e?.preventDefault();
    if (!currentRename) return;
    setCurrentRename("");
  };

  const handleRename = (e?: SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!currentRename) return;
    const newValue = currentRename === "empty" ? "" : currentRename;
    changeGroupName(index, newValue);
    setCurrentRename("");
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCurrentRename(value || "empty");
  };

  useOutsideClick(renameRef, () => handleCancelRename());

  const renameValue = currentRename === "empty" ? "" : currentRename;
  const draggableKey = `${item.id}-${item.createdAt}`;
  return (
    <Draggable draggableId={`status-group-${draggableKey}`} key={draggableKey} index={index}>
      {(provided: DraggableProvided) => (
        <div
          className={clsx(editMode && styles.statusGroupWrapper, isUnsynced && styles.unsynced)}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          {editMode && (
            <div {...provided.dragHandleProps} className={styles.dragHandle}>
              <DragIcon />
            </div>
          )}
          <div
            className={clsx(styles.statusGroup, isActiveGroup && styles.active)}
            onClick={handleClick}
          >
            {groupNumber < statusGroupAmount && <div className={styles.divider} />}
            <div className={styles.icon}>
              {totalProgress < 100 ? (
                <Progress
                  type='circle'
                  percent={totalProgress}
                  strokeLinecap={"square"}
                  size={32}
                  showInfo={false}
                  strokeWidth={8}
                />
              ) : (
                <StepGroupFinishedIcon />
              )}
            </div>
            <div className={styles.info}>
              {currentRename ? (
                <form onSubmit={handleRename} className={styles.renameForm} ref={renameRef}>
                  <Input
                    className={styles.input}
                    value={renameValue}
                    autoFocus
                    onChange={handleChangeName}
                  />
                  <EditButtons
                    className={styles.actionButtons}
                    discardClick={handleCancelRename}
                    acceptClick={handleRename}
                  />
                </form>
              ) : (
                <>
                  <div className={styles.topRow}>
                    <span className={styles.name}>{item.name}</span>
                    <span className={styles.outOf}>
                      {subStepsFinishedAmount}/{subStepsAmount}
                    </span>
                  </div>
                  {(filteredSteps?.length || []) > 0 && isActiveGroup && (
                    <div className={styles.botRow}>
                      {filteredSteps?.map((step) => (
                        <div
                          key={step.id}
                          className={clsx(styles.stepIndicator, step.status && styles[step.status])}
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {editMode && (
            <Dropdown
              rootClassName={styles.menu}
              onOpenChange={setIsMenuOpen}
              open={isMenuOpen}
              placement='bottomRight'
              menu={{ items: actionItems }}
              trigger={["click"]}
            >
              <Button color='gray' icon={<ThreeDotsIcon />} onClick={handleCancelRename} />
            </Dropdown>
          )}
          <DeleteModal
            open={confirmDeleteOpen}
            onCancel={() => setConfirmDeletePopup(false)}
            onDelete={() => deleteGroup(index)}
            content={
              <Trans
                t={t}
                i18nKey='delete_document_confirmation_message'
                values={{ documentName: item.name }}
                components={{ b: <b /> }}
              />
            }
          />
        </div>
      )}
    </Draggable>
  );
}
