import API from "./API.js";

export const getCompany = async (id, params) => {
  try {
    return await API().get(`/company/${id}`, params);
  } catch (error) {
    return error;
  }
};
export const allRequests = async (id, params) => {
  try {
    const response = await API().get(`/audit/${id}/requests`, params);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getRequests = async (id, statusFilter) => {
  const res = await API().get(`/audit/${id}/requests`, {
    params: {
      statusFilter,
    },
  });
  return res.data;
};
export const getRequest = async (id, requestId) => {
  return await API().get(`/audit/${id}/requests/${requestId}`);
};

export const detachDocument = async (id, requestId, documentId) => {
  return await API().post(`/audit/${id}/requests/${requestId}/detach-document`, {
    documentId,
  });
};

export const detachQuoteDocument = async (id, documentId) => {
  return await API().post(`/audit/${id}/requests/quote/detach-document`, {
    documentId,
  });
};

export const getRequestByType = async (id, type) => {
  return await API().get(`/audit/${id}/requests/by_type/${type}`);
};

export const oneRequests = async (id, requestId) => {
  try {
    const response = await API().get(`/audit/${id}/requests/${requestId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addRequests = async (body, id) => {
  try {
    const FD = new FormData();

    FD.append("name", body.name);
    FD.append("description", body.description);
    body.dueDate && FD.append("dueDate", body.dueDate);
    body.dueTime && FD.append("dueTime", body.dueTime);
    body.assignedUsers.length && FD.append("assignedUsers", body.assignedUsers);
    body.assignedRoles.length && FD.append("assignedRoles", body.assignedRoles);
    // body.attachments.forEach((item, index) => {
    //   FD.append(`file${index}`, item);
    // });
    FD.append("request_type", body.request_type || "data_transfer_request");
    FD.append("security", body.security);

    const response = await API().post(`/audit/${id}/requests`, FD);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const addRequestsComment = async (body, id, requestId) => {
  try {
    const response = await API().post(`/audit/${id}/requests/${requestId}/comment`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const upDateRequests = async (body, id, requestId, config) => {
  try {
    const FD = new FormData();

    Object.entries(body).forEach((i) => {
      if (Array.isArray(i[1]) && i[0] === "attachments") {
        i[1].forEach((i, index) => {
          FD.append(`file${index}`, i);
        });
      } else {
        if (i[0] !== "dueDate" && i[0] !== "dueTime") {
          if (i[0] === "assignedRoles" || i[0] === "assignedUsers") {
            FD.append(i[0], i[1].length ? i[1] : "[]");
          } else {
            FD.append(i[0], i[1]);
          }
        } else {
          if (i[1]) {
            FD.append(i[0], i[1]);
          }
        }
      }
    });

    const response = await API().post(`/audit/${id}/requests/${requestId}/update`, FD, config);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const sendRequest = async (id, requestId) => {
  try {
    const response = await API().post(`/audit/${id}/requests/${requestId}/send`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateQuoteRequest = async (id, data, config) => {
  try {
    const FD = new FormData();

    Object.entries(data).forEach((i) => {
      if (Array.isArray(i[1]) && i[0] === "attachments") {
        i[1].forEach((i, index) => {
          FD.append(`file${index}`, i);
        });
      } else if (i[0] === "preparer") {
        FD.append("preparer", i[1]);
      } else {
        if (i[0] !== "dueDate" && i[0] !== "dueTime") {
          if (i[0] === "assignedRoles" || i[0] === "assignedUsers") {
            FD.append(i[0], i[1].length ? i[1] : "[]");
          } else {
            FD.append(i[0], i[1]);
          }
        } else {
          if (i[1]) {
            FD.append(i[0], i[1]);
          }
        }
      }
    });

    const response = await API().post(`/audit/${id}/requests/quote/update`, FD, config);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const sendQuoteRequest = async (id, data, config) => {
  try {
    const FD = new FormData();

    Object.entries(data).forEach((i) => {
      if (Array.isArray(i[1]) && i[0] === "attachments") {
        i[1].forEach((i, index) => {
          FD.append(`file${index}`, i);
        });
      } else if (i[0] === "preparer" && i[1]) {
        FD.append("preparer", i[1].id);
      } else {
        if (i[0] !== "dueDate" && i[0] !== "dueTime") {
          if (i[0] === "assignedRoles" || i[0] === "assignedUsers") {
            FD.append(i[0], i[1].length ? i[1] : "[]");
          } else {
            FD.append(i[0], i[1]);
          }
        } else {
          if (i[1]) {
            FD.append(i[0], i[1]);
          }
        }
      }
    });

    const response = await API().post(`/audit/${id}/requests/quote/send`, FD, config);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const rejectRequest = async (id, data, requestId) => {
  const FD = new FormData();

  Object.entries(data).forEach((i) => {
    if (Array.isArray(i[1]) && i[0] === "attachments") {
      i[1].forEach((i, index) => {
        FD.append(`file${index}`, i);
      });
    } else if (i[0] === "preparer" && i[1]) {
      FD.append("preparer", i[1].id);
    } else {
      if (i[0] !== "dueDate" && i[0] !== "dueTime") {
        if (i[0] === "assignedRoles" || i[0] === "assignedUsers") {
          FD.append(i[0], i[1].length ? i[1] : "[]");
        } else {
          FD.append(i[0], i[1]);
        }
      } else {
        if (i[1]) {
          FD.append(i[0], i[1]);
        }
      }
    }
  });

  try {
    const response = await API().post(`/audit/${id}/requests/${requestId}/reject`, FD);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const approveSubmitRequest = async (id, data, requestId) => {
  const FD = new FormData();

  Object.entries(data).forEach((i) => {
    if (Array.isArray(i[1]) && i[0] === "attachments") {
      i[1].forEach((i, index) => {
        FD.append(`file${index}`, i);
      });
    } else if (i[0] === "preparer" && i[1]) {
      FD.append("preparer", i[1].id);
    } else {
      if (i[0] !== "dueDate" && i[0] !== "dueTime") {
        if (i[0] === "assignedRoles" || i[0] === "assignedUsers") {
          FD.append(i[0], i[1].length ? i[1] : "[]");
        } else {
          FD.append(i[0], i[1]);
        }
      } else {
        if (i[1]) {
          FD.append(i[0], i[1]);
        }
      }
    }
  });

  try {
    const response = await API().post(`/audit/${id}/requests/${requestId}/approve`, FD);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const attachExistingFile = async (id, requestId, body, config) => {
  try {
    const response = await API().post(
      `/audit/${id}/requests/${requestId}/attach-document`,
      body,
      config
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const approveRequest = async (id, requestId) => {
  try {
    const response = await API().post(`/audit/${id}/requests/${requestId}/approve`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteRequest = async (id, requestId) => {
  try {
    const response = await API().delete(`/audit/${id}/requests/${requestId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
