import { getFileExtensionFromUrl } from "../../../utils/general";

import styles from "./FileExtension.module.scss";

const colorType = {
  pdf: "#FF5454",
  xlsx: "#34A64D",
  docx: "#1D88D6",
  jpeg: "#2b7cd3",
  jpg: "#2b7cd3",
  png: "#2b7cd3",
};

type FileExtensionProps = {
  document: string;
};

const FileExtension = ({ document }: FileExtensionProps) => {
  return (
    <span
      style={{
        background: colorType[getFileExtensionFromUrl(document) as keyof typeof colorType],
      }}
      className={styles.fileType}
    >
      {getFileExtensionFromUrl(document)}
    </span>
  );
};

export default FileExtension;
